import whiteCartAnimation from "@/assets/lottie/cart_white.json";
import useCheckoutState from "@/store/store";
import { DotLottiePlayer } from "@dotlottie/react-player";

const ProcessingPaymentLoader = () => {
    const { isProcessingPayment } = useCheckoutState();

    return (
        isProcessingPayment && (
            <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full text-center bg-black bg-opacity-70">
                <DotLottiePlayer
                    autoplay
                    src={whiteCartAnimation}
                    loop={true}
                    style={{ height: "200px", width: "200px" }}
                />
            </div>
        )
    )
}

export default ProcessingPaymentLoader
