/* eslint-disable @typescript-eslint/no-explicit-any */
import Api from "@/api/Api";
import useCheckoutState from "@/store/store";
import i18n from "@/utils/i18n";
import { getQueryParams } from "@/utils/query";
import { QueryClient, useQuery } from "@tanstack/react-query";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

export interface PaymentDetails {
  firstName: string;
  lastName: string;
  email: string;
  cardNumber: string;
  expiryDate: string;
  cvc: string;
}

export interface CheckoutContextType {
  paymentDetails: PaymentDetails;
  setPaymentDetails: (details: PaymentDetails) => void;
  isLoading: boolean;
  isLoadingDiscount: boolean;
  discountError: boolean;
  setIsLoadingDiscount: (value: boolean) => void;
  setDiscountError: (value: boolean) => void;
  isError: boolean;
  error: any;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  triggerSubmit: () => void;
  setSubmitFunction: (submitFn: () => void) => void;
  validateDiscountCode: (code: string) => void;
}

export const CheckoutContext = createContext<CheckoutContextType | undefined>(
  undefined,
);

interface CheckoutProviderProps {
  children: ReactNode;
}

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const qParams = getQueryParams(["product_tk", "user_tk", "order_tk"]);
  const navigate = useNavigate();

  const queryClient = new QueryClient();

  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [discountError, setDiscountError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
    firstName: "",
    lastName: "",
    email: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
  });

  const {
    data: prevData,
    setData,
    setUserInfo,
    setPartitaIva,
    setPaymentMethod,
    setIsTutoring,
    setIsEasyPeasy,
    setStartMonth,
    setIsLoadingData,
    setLang,
    setAcceptedDiscount,
    vParams,
    setCurrency,
  } = useCheckoutState();

  const params = useMemo(
    () => ({
      ...qParams,
      ...vParams,
    }),
    [qParams, vParams],
  );

  const getDiscount = useCallback(
    async (code: string) => {
      const data = {
        order_tk: params.order_tk,
        discount_code: code,
      };
      try {
        const response = await Api.post("v3/checkout/get-discount", data);
        return response.data;
      } catch {
        return { data: null };
      }
    },
    [params.order_tk],
  );

  const validateDiscountCode = async (code: string) => {
    code = code.toUpperCase();

    if (!code) {
      setDiscountError(true);
      return false;
    } else {
      setDiscountError(false);
    }

    setIsLoadingDiscount(true);

    const data = await getDiscount(code).catch(() => {
      return null;
    });

    if (data?.product) {
      setDiscountError(false);

      const newData = {
        ...prevData,
        product: {
          ...prevData?.product,
          ...data.product,
          discount_code: code,
        },
      };

      setData(newData);
      queryClient.setQueryData(["checkout"], newData);

      setAcceptedDiscount(true);
    } else {
      setDiscountError(true);
    }

    setIsLoadingDiscount(false);
  }

  const fetchCheckoutData = async (): Promise<any> => {
    if (!params.product_tk && !params.user_tk && !params.order_tk) {
      throw new Error("Missing params");
    }

    //@ts-expect-error - ignore
    const { data: responseData, error } = await Api.post(
      "v3/checkout/start-session",
      {
        order_tk: params.order_tk,
        product_tk: params.product_tk,
        user_tk: params.user_tk,
      },
    ).catch((e) => {
      return { data: null, error: e?.response?.data || e };
    });

    if (error?.data) {
      if (
        error?.message === "Order already completed" &&
        !window.location.pathname.includes("payment-success")
      ) {
        navigate(
          `/order/${i18n.language}/${error.data.order.token}/payment-success`,
          { replace: true },
        );
      }

      return error.data;
    }

    const { data } = responseData;
    const path = window.location.pathname;
    const search = window.location.search;
    const supported_lang = ["it", "es", "de", "pt", "fr", "en"];

    if (data.user_detected_locale) {
      setLang(data.user_detected_locale);

      if (!path.includes(data.user_detected_locale + "/")) {
        const arrPath = path
          .split("/")
          .filter((v) => v != "" && v != "checkout");

        if (!supported_lang.includes(arrPath[1])) {
          const prevVal = arrPath[1];
          arrPath[1] = data.user_detected_locale;
          arrPath.push(prevVal);

          i18n.changeLanguage(data.user_detected_locale);
          navigate(`/${arrPath.join("/")}${search}`, { replace: true });
        }
      }
    }

    const startMonth = data?.order?.start_month;

    if (startMonth) {
      setStartMonth(startMonth);
    }

    if (data?.user?.detected_locale && data.user_detected_locale !== "it") {
      setIsEasyPeasy(true);
    }

    return data;
  };

  const {
    data: checkoutData,
    isLoading,
    isError,
    error,
  } = useQuery<any>({
    queryKey: ["checkout"],
    queryFn: fetchCheckoutData,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
  });

  useEffect(() => {

    setIsLoadingData(isLoading);
    if (isLoading || !checkoutData) return;

    setData(checkoutData);
    setIsTutoring(Boolean(checkoutData?.product?.tutoring));
    setPartitaIva(Boolean(checkoutData?.product?.discount_code));
    setPaymentMethod(checkoutData?.product?.discount_code);
    setUserInfo(checkoutData?.user);
    setCurrency({ currency: checkoutData?.checkout_settings?.currency, symbol: checkoutData?.checkout_settings?.currency_symbol });
  }, [
    checkoutData,
    isLoading,
    setIsLoadingData,
    setData,
    setIsTutoring,
    setPartitaIva,
    setPaymentMethod,
    setUserInfo,
    setCurrency,
  ]);

  const submitFnRef = useRef<() => void>(() => { });

  const triggerSubmit = useCallback(() => {
    if (submitFnRef.current) {
      submitFnRef.current();
    }
  }, []);

  const setSubmitFunction = (submitFn: () => void) => {
    submitFnRef.current = submitFn;
  };

  const contextValue = {
    paymentDetails,
    setPaymentDetails,
    isLoading,
    isError,
    error,
    isLoadingDiscount,
    discountError,
    setDiscountError,
    isDisabled,
    setIsDisabled,
    validateDiscountCode,
    triggerSubmit,
    setSubmitFunction,
    setIsLoadingDiscount,
  }

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutSteps = (): CheckoutContextType => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return context;
};
