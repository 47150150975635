import LinearProgress from "@/components/LinearProgress.tsx";
import AllRoutes from "@/routes";
import { Suspense } from "react";
import { Toaster } from 'react-hot-toast';
import ProcessingPaymentLoader from "./components/ProcessingPaymentLoader";

function App() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AllRoutes />
      <Toaster />

      <ProcessingPaymentLoader />
    </Suspense>
  );
}

export default App;
